import React from "react";
import "./AboutUs.css";
import { Link } from 'react-router-dom';

const AboutUs = () => {
  return (
    <>
      <div className="about-us-card">
        <div className="about-us-image-container">
          <img
            src={`${process.env.PUBLIC_URL}/images/favicon.ico`}
            alt="Aarti Niradhar Foundation"
            className="about-us-image"
          />
        </div>
        <div className="about-us-text">
          <h1 className="about-us-title">Aarti Niradhar Foundation</h1>
          <p className="about-us-description">
            Established on <strong>21st January 2018</strong> by <strong>Anita Atmaram Narkar</strong>, Aarti Niradhar Foundation is a non-profit organization dedicated to providing care and support to the elderly and mentally unstable individuals. Our mission is to ensure dignity, comfort, and quality of life for all our residents.
          </p>
          <p className="about-us-description">
            We provide <strong>safe living spaces</strong>, nutritious meals, and recreational activities that promote well-being and happiness. Through holistic care, we aim to empower every individual who walks through our doors.
          </p>
          
          {/* New "Our Vision" section */}
          <section className="about-us-vision">
            <h2 className="about-us-vision-title">Our Vision</h2>
            <p className="about-us-vision-description">
              Our vision is a world where no elderly or mentally unstable person is left without the care and support they deserve. We envision a society that treats its elders and vulnerable individuals with respect, empathy, and compassion, creating an environment that fosters mental and physical well-being.
            </p>
            <p className="about-us-vision-description">
              We are committed to being a guiding light for those in need, providing not just care, but also dignity and purpose, so that our residents may lead their lives to the fullest.
            </p>
          </section>

          <p className="about-us-donation">
            As a part of our operations, we accept donations and service fees to cover living, food, and care expenses. Your contributions help us sustain and improve the quality of services we provide to those in need.
          </p>
        </div>
      </div>

      {/* Donate Now Button */}
      <Link to="/donation">
        <button className="donate-button">DONATE NOW</button>
      </Link>
    </>
  );
};

export default AboutUs;
