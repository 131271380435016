import React from "react";
import "./ContactUs.css";
import { Link } from "react-router-dom";

const ContactUs = () => {
    return (
        <>
            <div className="contact-container">
                <h2 className="contact-header">Get in Touch</h2>
                <p className="contact-intro">
                    We’d love to hear from you! Feel free to reach out via any of the methods below.
                </p>

                {/* Contact Details Section */}
                <div className="contact-details">
                    <div className="contact-info">
                        <h3>Contact Details</h3>
                        <table className="contact-table">
                            <tbody>
                                <tr>
                                    <td className="contact-label">
                                        <i className="fas fa-map-marker-alt location-icon"></i> Address 1:
                                    </td>
                                    <td className="contact-value">
                                        At-Post Phede, Pharshi, Near Cement Kamdhenu, Chiplun, Ratnagiri
                                    </td>
                                </tr>
                                <tr>
                                    <td className="contact-label">
                                        <i className="fas fa-map-marker-alt location-icon"></i> Address 2:
                                    </td>
                                    <td className="contact-value">
                                        At-Post Malghar, Gurukul High School
                                    </td>
                                </tr>
                                <tr>
                                    <td className="contact-label">
                                        <i className="fas fa-envelope location-icon"></i> Email:
                                    </td>
                                    <td className="contact-value">
                                        <a href="mailto:info.ansfoundation@gmail.com">info.ansfoundation@gmail.com</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="contact-label">
                                        <i className="fas fa-phone-alt location-icon"></i> Phone No:
                                    </td>
                                    <td className="contact-value">
                                        7666686643, 8329884408 , 8779529843
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {/* Social Media Section */}
                    <div className="contact-social">
                        <h3>Follow Us</h3>
                        <p>Stay connected with us on social media:</p>
                        <div className="social-icons">
                            <a href="https://facebook.com" target="_blank" rel="noreferrer" aria-label="Facebook">
                                <i className="fab fa-facebook"></i>
                            </a>
                            <a href="https://twitter.com" target="_blank" rel="noreferrer" aria-label="Twitter">
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a href="https://instagram.com" target="_blank" rel="noreferrer" aria-label="Instagram">
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a href="https://linkedin.com" target="_blank" rel="noreferrer" aria-label="LinkedIn">
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <a href="https://youtube.com/@amolnarkar8107?si=x-UUBItCu9Sua8sk" target="_blank" rel="noreferrer" aria-label="YouTube">
                                <i className="fab fa-youtube"></i>
                            </a>
                        </div>
                    </div>
                </div>

                {/* Google Maps Section */}
               {/* Google Map Section */}
<div className="contact-map">
    <h3>Find Us</h3>

    {/* Map 1 */}
    <div className="map-container">
        <h4 className="map-label">Address 1 : Phede, Pharshi, Near Cement Kamdhenu</h4>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.342184710574!2d73.515687!3d17.530932!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc209e804c4fd17%3A0x38aeb94d6cf575ce!2sAarti%20Niradhar%20Seva%20Foundation!5e0!3m2!1sen!2sin!4v1697012345678!5m2!1sen!2sin"
            width="100%"
            height="300"
            allowFullScreen=""
            loading="lazy"
            title="Google Map - Aarti Niradhar Seva Foundation"
        ></iframe>
    </div>

    {/* Map 2 */}
    <div className="map-container">
        <h4 className="map-label">Address 2 : Malghar, Gurukul High School</h4>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3779.854183730000!2d73.525000!3d17.538000!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc225bbbbbbbbbb%3A0xcccccccccccccccc!2sMalghar%2C%20Gurukul%20High%20School%2C%20Chiplun%2C%20Ratnagiri%2C%20Maharashtra%20415302!5e0!3m2!1sen!2sin!4v1697000000001!5m2!1sen!2sin"
            width="100%"
            height="300"
            allowFullScreen=""
            loading="lazy"
            title="Google Map - Malghar, Gurukul High School"
        ></iframe>
    </div>
</div>


                {/* Donate Now Button */}
                <Link to="/donation">
                    <button className="donate-button">DONATE NOW</button>
                </Link>
            </div>
        </>
    );
};

export default ContactUs;
